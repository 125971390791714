import React from "react";
import { Col, Form, InputGroup, Spinner } from "react-bootstrap";
import { get as _get } from "lodash";

export const FormDropDown = (props) => {
  const {
    intl,
    style,
    required,
    disabled,
    firstOptionValue,
    disabledFeedback,
    xs,
    lg,
    className,
    plainTitle,
    titleId,
    valueRef,
    overrideValue,
    values,
    options,
    errors,
    touched,
    handleChange,
    handleBlur,
    isLoading,
    hasPlaceholderOption = true,
    hidden = false,
    boldLabel = false,
  } = props;
  const touchedValue = _get(touched, valueRef);
  const errorValue = _get(errors, valueRef);
  const isInvalid =
    (touched && errors && Boolean(touchedValue && errorValue)) ?? false;
  const isValid =
    (touched && errors && Boolean(touchedValue && !errorValue)) ?? false;

  const feedbackEnabled = !disabledFeedback ?? true;

  const emptyOptions = (options ?? []).length === 0;

  if (hasPlaceholderOption) {
    if (!options.find((el) => el.id === "slt")) {
      options.push({
        id: "slt",
        label: intl.formatMessage({
          id: isLoading
            ? "LIST.LOADING"
            : emptyOptions
            ? "LIST.EMPTY"
            : "LIST.SELECT",
        }),
      });
    }
  }

  const firstOptionMessage =
    firstOptionValue || firstOptionValue === 0
      ? options[firstOptionValue].label
      : options[options.length - 1].label;

  const ThisForm = () => (
    <>
      <Form.Control
        style={{ backgroundPosition: "right calc(1em + 0.325rem) center" }}
        as="select"
        required={required ?? false}
        disabled={(disabled ?? false) || emptyOptions}
        key={valueRef}
        id={valueRef}
        name={valueRef}
        placeholder={
          plainTitle ? plainTitle : intl.formatMessage({ id: titleId })
        }
        value={overrideValue ?? _get(values, valueRef) ?? ""}
        isInvalid={isInvalid && feedbackEnabled}
        isValid={isValid && feedbackEnabled}
        onChange={handleChange}
        onBlur={handleBlur}
        className={className}
      >
        {hasPlaceholderOption && (
          <option value="" disabled={!firstOptionValue}>
            {firstOptionMessage}
          </option>
        )}
        {options
          ?.filter((el) => {
            if (hasPlaceholderOption) {
              return el.label !== firstOptionMessage
            } else {
              return true
            }  
          })
          .map((el) => (
            <option key={el.id} value={el.id}>
              {el.label}
            </option>
          ))}
      </Form.Control>
    </>
  );

  return (
    <Form.Group as={Col} xs={xs ?? 12} lg={lg ?? 4} style={{ display: hidden ? "none" : "block", ...style }}>
      <Form.Label style={{ fontWeight: boldLabel ? "bold" : "normal" }}>
        {plainTitle ? plainTitle : intl.formatMessage({ id: titleId })}
      </Form.Label>
      {isLoading && (
        <InputGroup>
          <ThisForm />
          <InputGroup.Append>
            <Spinner
              animation="grow"
              variant="success"
              style={{ alignSelf: "center", marginLeft: 8 }}
            />
          </InputGroup.Append>
        </InputGroup>
      )}
      {!isLoading && <ThisForm />}
      {feedbackEnabled && (
        <Form.Control.Feedback type="invalid">
          {errors && errorValue}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
