import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Col,
  Form,
  InputGroup
} from "react-bootstrap";
import { get as _get } from "lodash"
import InputMask from "react-input-mask";

export const NewFormTextField = (props: any) => {
  const { 
    intl, required, disabled, xs, lg, className, titleId, commentId, 
    valueRef, overrideValue, values, type, errors, touched, handleChange, handleBlur,
    withMask, withAppendText, withPrependText, groupClassName, boldLabel = false
  } = props
  const isInvalid = (touched && errors) && Boolean(touched[valueRef] && errors[valueRef])
  const isValid   = (touched && errors) && Boolean(touched[valueRef] && !errors[valueRef])

  const fieldProps = {
    required    : required ?? false,
    disabled    : disabled ?? false,
    key         : valueRef,
    id          : valueRef,
    name        : valueRef,
    type        : type ?? "text",
    placeholder : intl.formatMessage({id: titleId}),
    value       : overrideValue ?? _get(values, valueRef) ?? "",
    onBlur      : handleBlur,
    className   : className,
    onChange    : handleChange
  }

  return (
    <Form.Group className={groupClassName} as={Col} xs={xs ?? 12} lg={lg ?? 4}>
      <Form.Label style={{ fontWeight: boldLabel ? "bold" : "normal" }}><FormattedMessage id={titleId} /></Form.Label>
      <InputGroup>
      {
        (withPrependText) && (
          <InputGroup.Prepend>
            <InputGroup.Text style={{ minWidth:50, textAlign:"right" }}>{ withPrependText }</InputGroup.Text>
          </InputGroup.Prepend>
        )
      }
      {
        (withMask) && ( 
          <>
            <InputMask 
              {...fieldProps} 
              maskPlaceholder="" 
              className={`form-control ${isInvalid && "is-invalid"}`} 
              mask={withMask}
              />
            {isInvalid && (
              <Form.Control.Feedback type="invalid">{errors[valueRef]}</Form.Control.Feedback>)}
          </>
        )
      }
      {
        !(withMask) && (
          <Form.Control
            {...fieldProps}
            isInvalid={isInvalid ?? false}
            isValid={isValid ?? false}
          />
        )
      }
      {
        (withAppendText) && (
          <InputGroup.Append>
            <InputGroup.Text style={{ minWidth:50, textAlign:"right" }}>{ withAppendText }</InputGroup.Text>
          </InputGroup.Append>
        )
      }
      </InputGroup>
      { (commentId) && <Form.Label className="d-block" style={{color: '#999', fontSize: 11 }}><FormattedMessage id={commentId} /></Form.Label> }
      <Form.Control.Feedback type="invalid">{ errors && errors[valueRef]}</Form.Control.Feedback>
    </Form.Group>
  )
}
