export const notFoundPath         = "/error/error-not-found"
export const homeBasePath         = "/home"

export const monitoringPath             = "/monitoring"
export const monitoringSectorPath       = `${monitoringPath}/sector/:id`
// export const monitoringSpecificPath  = `${monitoringPath}/sector/:id/monitoring/:monitoringId` // In the future, maybe...
export const monitoringLPPDashboardPath = `${monitoringPath}/sector/:id/lpp-dashboard`

export const overviewReportsPath   = "/reports/overview"
export const dashboardReportsPath  = "/reports/dashboard"

export const pacientsPath            = "/pacients"
export const patientEvaluationsPath  = `${pacientsPath}/:id/evaluations`

export const medicinesPath           = "/medicines"
export const devicesPath             = "/devices"
export const sectorsPath             = "/sectors"
export const wifisPath               = "/wifis"
export const usersPath               = "/users"
export const indicatorsPath          = "/indicators"
export const logsPath                = "/logs"
export const evaluationContractsPath = "/evaluation-contracts"

export const userProfileBasePath  = "/user-profile"
