import { BaseModel } from "./base"

export type PatientDocumentType = "CPF" | "RG"

export interface IPacient {
  id: string
  medicalRecord?: string
  location?: string

  name: string
  socialName?: string
  nameOrdening?: string | null

  birthday: string

  sex?: string
  genderIdentity?: string
  sexualOrientation?: string

  documentType: PatientDocumentType
  rg?: string
  cpf?: string

  civil_state?: string
  phone?: string
  email?: string

  zip_code?: string
  address?: string
  complement?: string
  number?: string
  neighborhood?: string
  city?: string
  state?: string

  emergencyContactName?: string
  emergencyContactRelationship?: string
  emergencyContactPhone?: string

  description?: string
  healthHistory?: string

  sectorId?: string | null
  deviceId?: string | null
  monitoringId?: string | null
  deletedAt?: number | null
  deletedBy?: string | null
}

export interface IPacientMonitoring {
  sectorId?: string | null
  deviceId?: string | null
  monitoringId?: string | null
}

export class Pacient extends BaseModel implements IPacient, IPacientMonitoring {
  readonly id: string
  readonly medicalRecord?: string
  readonly location?: string

  readonly name: string
  readonly socialName?: string
  readonly nameOrdening?: string | null

  readonly birthday: string

  readonly sex?: string
  readonly genderIdentity?: string
  readonly sexualOrientation?: string

  readonly documentType: PatientDocumentType
  readonly rg?: string
  readonly cpf?: string

  readonly civil_state?: string
  readonly phone?: string
  readonly email?: string

  readonly zip_code?: string
  readonly address?: string
  readonly complement?: string
  readonly number?: string
  readonly neighborhood?: string
  readonly city?: string
  readonly state?: string

  readonly emergencyContactName?: string
  readonly emergencyContactRelationship?: string
  readonly emergencyContactPhone?: string

  readonly description?: string
  readonly healthHistory?: string

  readonly deviceId?: string | null
  readonly sectorId?: string | null
  readonly monitoringId?: string | null
  readonly deletedAt?: number | null
  readonly deletedBy?: string | null

  constructor(args: IPacient) {
    super()

    this.id                = args.id
    this.medicalRecord     = args.medicalRecord
    this.location          = args.location

    this.name              = args.name ?? ""
    this.socialName        = args.socialName ?? ""
    this.nameOrdening      = args.nameOrdening ?? null

    this.birthday          = args.birthday ?? ""

    this.sex               = args.sex
    this.genderIdentity    = args.genderIdentity ?? ""
    this.sexualOrientation = args.sexualOrientation ?? ""

    this.documentType      = args.documentType
    this.rg                = args.rg ?? ""
    this.cpf               = args.cpf ?? ""

    this.civil_state       = args.civil_state ?? ""
    this.phone             = args.phone ?? ""
    this.email             = args.email ?? ""

    this.zip_code          = args.zip_code ?? ""
    this.address           = args.address ?? ""
    this.complement        = args.complement ?? ""
    this.number            = args.number ?? ""
    this.neighborhood      = args.neighborhood ?? ""
    this.city              = args.city ?? ""
    this.state             = args.state ?? ""

    this.emergencyContactName         = args.emergencyContactName ?? ""
    this.emergencyContactRelationship = args.emergencyContactRelationship ?? ""
    this.emergencyContactPhone        = args.emergencyContactPhone ?? ""

    this.description       = args.description ?? ""
    this.healthHistory     = args.healthHistory ?? ""

    this.sectorId          = args.sectorId ?? null
    this.deviceId          = args.deviceId ?? null
    this.monitoringId      = args.monitoringId ?? null
    this.deletedAt         = args.deletedAt ?? null
    this.deletedBy         = args.deletedBy ?? null
  }

  getFormattedName(): string | undefined {
    const _name          = (this.name ?? "")
    const _medicalRecord = (this.medicalRecord ? `${this.medicalRecord} - ` : undefined) ?? ""
    const formattedName  = _medicalRecord + _name
    
    return (formattedName.length > 0) ? formattedName : undefined
  }
}
